.browse-button{
    text-align: center;
    background-color: #ba0c2f;
    color: white;
    border-radius: 10px;
    height: 32px;
    font-family: 'Open Sans', serif;
    padding: 10px 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    border: none;
    cursor: pointer;
    margin: auto;
}

.browse-button:visited{
    color: white;
}