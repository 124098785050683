.CardList {
    background-color: var(--lightgray);
    overflow: hidden;
    padding: 10px 0;
    margin-top: 10px;
}

.classHeader {
    text-align: left;
    padding-left: 20px;
    user-select: none;
}

@media (min-width: 850px){
    .CardList{
        margin-left: 9vw;
        margin-right: 13vw;
    }

}


