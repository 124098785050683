.search-bar {
    display: flex;
    text-align: initial;
    background-color: white;
    box-shadow: 0px 10px 15px 3px rgba(0, 0, 0, 0.03);
}

.search-bar .container {
    display: flex;
    flex-direction: column;
    margin: 20px 120px;
    background-color: white;
    border-radius: 0;
    box-shadow: none;
}

.search-bar .container .search-container input {
    background-color: #e5e5e5;
    border: none;
    border-radius: 10px 0 0 10px;
    width: 20em;
    font-family: "Open Sans";
    font-size: 14px;
}

.search-bar .container .search-container input:focus ~ .dropdown {
    display: block;
}

.search-container .dropdown {
    display: none;
    position: absolute;
    /*background-color: #f6f6f6;*/
    min-width: 230px;
    width: 18em;
    height: auto;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 10px 15px 3px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    margin-top: 61px;
}

.search-container .dropdown:hover {
    display: block;
}

.search-container .dropdown .container {
    display: flex;
    flex-direction: column;
    border-radius: inherit;
    margin: 0;
}

.search-container .dropdown .container .item {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    padding: 0 10px;
}

.search-container .dropdown .container .item:hover{
    background-color: #E5E5E5;
}

.search-container .dropdown .container .item p {
    color: black;
    padding: 4px 10px;
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-family: "Open Sans";
}

.search-page {
    border-radius: 0 0 30px 30px;
    background-color: white;
}

.search-page h1{
    font-size: 1.5em;
    user-select: none;
}

.search-page .container {
    background-color: white;
    border-radius: 0 0 30px 30px;
}

.search-page .clusters {
    background-color: unset;
    border-radius: 0;
}


.search-page .clusters .container {
    display: flex;
    overflow-x: auto;
    overflow-y: visible;
    background-color: unset;
    border-radius: 0;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
}

.search-page .clusters .container > a {
    scroll-snap-align: center;
}

.search-page .clusters a:first-child{
    margin-left: 10px;
}
.search-page .clusters a:last-child{
    margin-right: 10px;
}

.search-container{
    display: flex;
}

.no-results{
    margin-left: 10px;
}

.search-icon{
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #ba0c2f;
    border-radius: 0 10px 10px 0;
    user-select: none;
}

.button {
    background-color: white;
}

/* Media Query for Mobile Devices */
@media (max-width: 600px) {
    .search-bar .container {
        margin: 20px 20px;
        width: 100%;
    }

    .search-bar .container .search-container input {
        width: -moz-available;
        padding: 20px;
        height: 20px;
    }
    .search-page .clusters h3 {
        margin-left: 10px;
    }
    .search-container .dropdown {
        width: 15em;
    }
}

@media (min-width: 600px) {
    .search-page {
        border-radius: 0 0 30px 30px;
        background-color: white;

    }
    .search-bar{
        box-shadow: none;
        display: flex;
        justify-content: flex-end;
    }
    .search-page .clusters .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        overflow-y: auto;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        margin-left: 10vw;
        margin-right: 10vw;
        gap: 20px;
        margin-bottom: 30px;
    }
    .search-page .container .search-container input {
        width: 18.5em;
        height: 20px;
    }

    .search-page .clusters {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .search-page .clusters a:first-child{
        margin-left: 0;
    }
    .search-page .clusters a:last-child {
        margin-right: 0;
    }

    a{
        overflow: hidden;
        margin-bottom: 20px;
    }
    .search-bar input {
        width: 10vw;
        padding: 20px;
    }


    .search-page .backhome{
        margin-left: 200px;
        margin-top: 50px;
    }
    .search-page h3 {
        font-size: 1.5em;
        margin-left: 10vw;
        user-select: none;
    }

    .search-page .container{
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        margin-right: 5vw;
    }
    .search-page .backhome p  {
        margin-bottom: 35px;
    }
    .dropdown .container {
        width: 100%;
    }


}
