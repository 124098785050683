.cluster-card {
    display: flex;
    flex-direction: row;
    margin: 0 10px;
    user-select: none;
}

.cluster-card .crop {
    display: block;
    border-radius: 10px;
    width: 268.66px;
    height: 392px;
    background: url('../images/icon_background.svg') center center/cover;
}

.cluster-card .crop .content {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
}

.cluster-card .crop .content .text h3 {
    margin: 15px 20px 0;
    color: white;
    font-style: normal;
    font-weight: 800;
    font-size: 27px;
    line-height: 115.68%;
}

.cluster-card .crop .content .sprite {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
}

.cluster-card .crop .content .sprite img {
    width: 170px;
    transform: translateY(10px);
}

@media (min-width: 850px){
    .cluster-card{
        margin: 0;
    }
}
