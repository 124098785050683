.WhiteCard {
    margin: 0 10px 15px;
    padding: 15px 15px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 15px 1px rgba(0, 0, 0, 0.03);
    text-align: left;
    list-style: none;
}

.WhiteCard:hover{
    background-color: #E5E5E5;
}

.WhiteCard h3 {
    margin: 0;
    font-weight: 700;
    font-size: 1.5em;
    padding-left: 5px;
}

.WhiteCard ul,
.WhiteCard .description {
    margin-top: 0.5em;
    color: var(--gray);
    font-size: 1em;
    line-height: 1.55em;
    list-style: inside;
    padding: 0 0 0 1.5em;
}

.WhiteCard .see-more {
    color: var(--red);
    text-align: right;
    font-size: 1em;
    margin: 0;
    user-select: none;
}

.description p:first-child {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    -webkit-text-overflow: ellipsis;
    overflow: hidden;
}
.description *:not(:first-child) {
    display:none;
}


a:visited{
    color: black ;
}

a{
    color: black;
}

.see-more-with-arrow img{
    height: .6em;
    user-select: none;
}

@media (min-width: 850px) {
    .WhiteCard {
        margin-bottom: 20px;
        padding: 20px 30px 10px;
    }

}
