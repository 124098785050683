.ClusterNav {
    padding: 20px 10px 20px 10px;
    text-transform: uppercase;
    text-align: left;
    font-size: 0.8em;
    background-color: var(--lightgray);
    user-select: none;
}

.ClusterNav img {
    margin: 0 5px;
    height: 10px;
}

.ClusterNav a {
    cursor: pointer;
}

.ClusterNav a:last-of-type {
    color: var(--red);
    font-weight: bold;
}
