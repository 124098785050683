.calling-card {
    display: flex;
    flex-direction: column;
    padding-top: 6vh;
}

a {
    text-decoration: none;
}

#text {
    display: flex;
    margin: 20px 0;
}

#text h3 {
    margin: 0;
    text-align: left;
    color: #ba0c2f;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    text-transform: uppercase;
}

#text p {
    margin: 0;
    text-align: left;
    color: black;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 41px;
    transition: 0.3s;
}

#sprite {
    background: url('../images/icon_background.svg') center center/cover
        no-repeat local;
    border: none;
    border-radius: 20px;
    display: flex;
    height: 183px;
    width: 300px;
    align-items: flex-end;
    flex-direction: column-reverse;
}

#sprite #crop {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    height: 120%;
}

#sprite #crop img {
    width: 70%;
    height: auto;
    transition: 0.3s;
    border-bottom-right-radius: 20px;
}

#click {
    overflow: hidden;
    text-align: center;
}

#click p {
    transform: translateY(-48px);
    transition: 0.3s;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 41px;
    color: black;
}

.calling-card:hover #text p {
    color: #ba0c2f;
}

.calling-card:hover #click p {
    transform: translateY(-20px);
}

/* Media Query for Mobile Devices */
@media (max-width: 850px) {
    #sprite {
        height: 333px;
        width: 325px;
    }

    #sprite #crop {
        height: 111%;
        width: 98%;
    }

    #sprite #crop img {
        width: fit-content;
        transition: 0.3s;
    }

    .calling-card:hover #sprite #crop img {
        width: unset;
    }

    .calling-card:hover #text p {
        color: black;
    }

    .calling-card:hover #click p {
        transform: translateY(-20px);
    }
}

@media (min-width: 850px) {
    #sprite #crop img{
        width: 90%;
    }
    .calling-card:hover #sprite #crop img {
        width: 100%;
    }
    #sprite{
        height: 260px;
        width: 375px;
    }


}
