.major-button-text {
    text-align: center;
    background-color: #ba0c2f;
    color: white;
    border-radius: 10px;
    width: 35vw;
    height: 25px;
    font-family: 'Open Sans', serif;
    padding: 10px 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
    border: none;
    cursor: pointer;
    margin: auto;
    user-select: none;
}
@media (max-width: 600px){
    .major-button-text{
        width: 40vw;
        font-size: 18px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.major-button-text:visited{
    color: white;
}

.major-button-div {
    display: flex;
    justify-content: center;
}
