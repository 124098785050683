.Header {
    background-color: white;
    position: relative;
    overflow: hidden;
    padding-right: 60px;
    box-shadow: 0px 4px 15px 1px rgba(0, 0, 0, 0.03);
}

.Header .back {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    background-color: white;
    cursor: auto;
}

Header ul, ol {
    list-style-type: unset;
}

.Header .back button {
    margin-left: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.Header .back p {
    margin: 0 0 0 5px;
    color: var(--red);
    font-size: 1em;
    cursor: pointer;
    user-select: none;
}

.Header .back img {
    max-width: 40px;
    max-height: 40px;
}

.Header .text {
    padding: 0 0 30px 30px;
    border-radius: 0 0 0 30px;
    background-color: white;
    text-align: left;
    line-height: 1.2;
}

.Header .text h1 {
    margin: 5px 0 10px 0;
    font-weight: 750;
    font-size: 1.7em;
}

.Header .text h2 {
    margin: 0;
    color: var(--red);
    font-size: 14px;
    user-select: none;
}

.Header .text p {
    margin: 1em 0 .5em;
    color: var(--gray);
}

.Header .occupation {
    font-weight: 500;
}

.Header span {
    display: block;
    margin-top: 5px;
    font-size: 0.8em;
    font-weight: 400;
    color: var(--gray);
}

.Header ul {
    padding: 0;
    margin: 0.5em 0;
    list-style-type: none;
}

.cluster-sprite {
    display: block;

}
.header-type{
    text-transform: uppercase;
}

@media screen and (max-width: 850px) {
    .Header .text .shrink {
        width: calc(80% - 20px);
    }
}

@media (min-width: 850px){
    .Header{
        background-color: #f4f4f4;
        border-radius: 0;
        box-shadow: none;
        padding-right: 40px;
    }

    .Header .back{
        background-color: #f4f4f4;
    }

    .Header .text {
        border-radius: 20px;
        margin-left: 9.5vw;
        margin-right: 10.5vw;
        display: flex;
        justify-content: space-between;
        padding: 20px;
    }
    .cluster-sprite{
        max-width: 140px;
        max-height: 240px;
        margin-bottom: -35px;
        margin-right: 40px;
    }
    .Header h1{
        font-size: 32px;
        padding-left:20px;
    }
    .Header .text h2{
        padding-left: 20px;
        font-size: 1em;
    }
    .Header .text p{
        font-size: 16px;
    }
    .occupations{
        padding-left: 20px;
    }
    .about-program{
        padding-left: 20px;
    }
    .major-header-text{
        padding-left: 20px;
    }
}

@media (max-width: 850px) {
    .cluster-sprite{
        position: absolute;
        bottom: 0;
        left: 100%;
        transform: translateX(-50%) translateY(10px);;
        max-width: calc(80% - 20px);
        height: calc(100% - 20px);
    }
}
