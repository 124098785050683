.TransferabilityCardWithMeter {
    margin: 30px 20px;
    padding: 10px 30px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 15px 1px rgba(0, 0, 0, 0.03);
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
}

.TransferabilityCard {
    margin: 30px 20px;
    padding: 10px 30px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 15px 1px rgba(0, 0, 0, 0.03);
}

.TransferabilityCard h3 {
    margin-bottom: 0;
    font-weight: 700;
    text-align: left;
}

.TransferabilityCard .description {
    list-style-type: unset;
    margin-top: 0.5em;
    color: var(--gray);
    font-size: 1em;
    line-height: 1.75em;
    text-align: left;
}

.TransferabilityCardWithMeter .description{
    list-style-type: unset;
    margin-top: 0.5em;
    color: var(--gray);
    font-size: 1em;
    line-height: 1.75em;
    text-align: left;
}

.transfer-meter {
    width: 125px;
    height: 125px;
    margin: auto;
    user-select: none;
}

.Text-Info-Div {
    margin-right: 20px;
}

.Meter-Div {
    display: flex;
    align-items: center;
}

@media (min-width: 850px) {
    .TransferabilityCard .description {
        font-size: 18px;
    }
    .TransferabilityCardWithMeter .description{
        font-size: 18px;
        list-style-type: unset;
        margin-top: 0.5em;
        color: var(--gray);
        line-height: 1.75em;
        text-align: left;
        display: flex;
        align-items: center;
    }
    .TransferabilityCard h3{
        font-size: 25px;
    }
    .TransferabilityCardWithMeter h3{
        font-size: 25px;
    }
    .TransferabilityCard {
        margin-right: 13vw;
        margin-left: 10vw;
    }
    .TransferabilityCardWithMeter{
        margin-left: 10vw;
        margin-right: 13vw;
        display: flex;
        flex-direction: row;
    }
    .transfer-meter{
        width: 200px;
        height: 200px;
        margin-top: 20px;
        margin-left: 10px;
    }
}
