@import url('https://fonts.googleapis.com/css?family=Open+Sans:800,700,500,400');

/* Colors */

:root {
    --red: #ba0c2f;
    --gray: #54585a;
    --lightgray: #f3f3f3;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    background: #f4f4f4;
}

a {
    text-decoration: none;
}

header {
    background-color: #ba0c2f;
    width: 100%;
}


header .container {
    display: flex;
    margin: auto;
    width: 97%;
    height: 60px;
}

/* THE WELCOME PAGE */

.welcome {
    display: block;
}

.welcome .information {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 15px 1px rgba(0, 0, 0, 0.03);
}

.welcome .head-text-wrap {
    width: 60%;
    margin: 50px auto 0;
    user-select: none;
}

.welcome .head-text-wrap p {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
}

.welcome .head-text-wrap h3 {
    margin: 10px 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bolder;
    font-size: 36px;
    line-height: 49px;
    color: #ba0c2f;
}

.welcome .text-wrap {
    width: 60%;
    margin: auto;
}

.welcome .text-wrap p {
    margin: 0;
    font-family: 'Open Sans';
    font-style: normal;
}

.welcome .text-wrap h2 {
    margin: 20px 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 45%;
    color: black;
    user-select: none;
}

.welcome .text-wrap section {
    margin: 10px 0;
}

.welcome .img-wrap {
    display: flex;
    justify-content: center;
    user-select: none;
}

.welcome span {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.welcome button {
    width: 450px;
    height: 54px;
    background-color: #ba0c2f;
    border-radius: 10px;
    border-style: none;
}

.welcome button a {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 31px;
    color: white;
}

.welcome p{
    user-select: none;
}

.App .mobile-header {
    display: none;
}

.App .selection-text {
    display: flex;
    margin: 40px 0;
    flex-direction: column-reverse;
}

.App .selection-text #text-1 {
    display: none;
    text-align: center;
    width: 100%;
    position: absolute;
    font-weight: bold;
    font-size: large;
}

.App .selection-text #text-2 {
    display: none;
    text-align: center;
    width: 100%;
    position: absolute;
    font-weight: bold;
    font-size: large;
}

.App .selection-text #text-3 {
    display: none;
    text-align: center;
    width: 100%;
    position: absolute;
    font-weight: bold;
    font-size: large;
}

.App nav {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App nav .links {
    display: flex;
    align-items: center;
    margin: auto;
}

.App nav .links a {
    display: flex;
    margin: 50px;
    user-select: none;
}

.App nav .mob-links {
    display: none;
}

.App a button {
    display: none;
}

.App .search-page .notice {
    font-family: sans-serif;
    font-size: 9px;
    text-align: center;
}

.backhome {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.backhome p{
    user-select: none;
}

.App #backbtn {
    text-align: initial;
    color: white;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    border: none;
    display: flex;
    margin: 0 10px 0 120px;
    padding: 0;
}

.App #backbtn p {
    font-size: 20px;
    text-align: center;
    display: contents;
}

.background {
    background-color: white;
    width: 100vw;
    border-radius: 20px;
}

#root > footer {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    position: sticky;
    top: 100vh;
    user-select: none;
    flex-direction: column;
    background: white;
}
#root > .no-background-footer{
    background: none;
}

#root > footer img{
    margin: auto;
}

.bsu-logo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.disclaimer {
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 14px;
    max-width: 80%;
    text-align: center;
}

.logo {
    width: 56px;
    height: 50px;
}

.main-container {
    padding-bottom: 90px;
}
#root {
    min-height: 100vh;
}
#paging {
    display: flex;
    width: 80vw;
    justify-content:center;
    align-items: center;
    list-style:none;
    padding-bottom: 20px;
    padding-top: 5px;
}
#paging li {

    height:14px;
    width:14px;
    border-radius:50%;
    border:1px solid #C4C4C4;
    cursor: pointer;
}

#paging li:nth-child(2){
    position: center;
}
#paging li + li { margin-left:1ch; }
#paging li.active { background:#C4C4C4; }

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    header .container {
        width: unset;
        margin: unset;
        justify-content: center;
    }

    header .container h1 {
        text-align: center;
        color: white;
    }

    .welcome .head-text-wrap {
        width: 87%;
        margin: 30px auto 0;
    }

    .welcome .head-text-wrap p {
        font-size: 27px;
    }

    .welcome .head-text-wrap h3 {
        font-size: 36px;
    }

    .welcome .text-wrap {
        width: 87%;
    }

    .welcome .img-wrap {
        width: 100%;
    }

    .welcome .img-wrap img {
        width: 40vh;
    }

    .welcome button {
        width: 250px;
        height: 68px;
    }

    .App .mobile-header {
        display: block;
        background-color: #ba0c2f;
        width: 100%;
        height: 30px;
    }

    .App nav .links {
        display: none;
    }

    .App nav {
        display: block;
    }

    .App nav .mob-links {
        display: flex;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
    }

    .App nav .mob-links > a {
        scroll-snap-align: center;
    }

    .App nav .mob-links a {
        flex-shrink: 0;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .App nav .mob-links a:hover {
    }

    .App #backbtn {
        width: 47px;
        margin: 0 20px;
        padding: 0;
    }

    .App a button {
        display: block;
        background-color: var(--red);
        width: 334px;
        height: 64px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 31px;
        color: white;
        border-radius: 10px;
        border-style: none;
        margin: 25px auto auto;
    }
    .desktop-animation {
        display: none;
    }
    .mobile-animation{
        max-width: 100%;
    }

}

@media (min-width: 500px) {
    .App {
        overflow-x: auto;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
    }
    .background{
        width: fit-content;
        margin: auto;
        border-radius: 0px 0px 77px 77px;;
    }
    .App nav .links{
        margin-left: 10px;
    }
    .App nav BrowseDualCreditLink{
        display: block;
    }
    #paging.mobile-dots{
        display: none;
    }
    .mobile-animation{
        display: none;
    }
    .desktop-animation{
        width: 60%;
    }
    .welcome .text-wrap section {
        margin: 25px 0;
    }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
}
