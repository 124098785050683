.CourseList {
    margin: 10px;
    padding: 10px 10px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 15px 1px rgba(0, 0, 0, 0.03);
    text-align: left;
}

.CourseList ul {
    padding: 0;
    margin: 0;
}

.CourseList li {
    padding: 0.5px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    color: black;
}

.CourseList li:hover{
    color:#BA0C2F;
}

.CourseList img{
    user-select: none;
    display: flex;
    align-items: center;
}
@media (max-width: 600px) {
    .CourseList li p{
        max-width: 90%;
    }
}


.gray {
    background-color: #e5e5e5;
}
